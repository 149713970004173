import React, { Component } from "react";
import axios from "axios";
import emailjs from "emailjs-com";
import "./CSS/Home.scss";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class Offer extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    emailSentMessage: "",
    ownershipIsLoaded: false,
    constructionDate: null,
    ownedByCorporation: null,
    ownedByCooperative: null,
    ownedByHousingCorporation: null,
    userInputBuildings: null,
    userInputConstructionDate: null,
    userInputBathRooms: null,
    userInputUsableArea: null,
    userInputOwnership: null,
    buildings: [],
    addressBuildings: [],
    floors: [],
    selectedFloor: null,
    ownership: [],
    occupiedUnits: [],
    separatelyOccupiedUnits: [],
    selectedUnit: {},
    Rooms: [],
    ident: [],
    street: [],
    numberOfBathRooms: [],
    numberOfRooms: [],
    usedDate: [],
    usableArea: [],
    buildingRelationship: "",
    kitchenCondition: "",
    buildingCondition: {
      fuktskader: false,
      elektrisitetfeil: false,
      fundamentskader: false,
      sopp: false,
      lekasje: false,
      skadedyr: false,
      annet: false,
    },
    facilities: {
      heis: false,
      balkong: false,
      balkongAnnenRetning: false,
      takterasse: false,
      fellesTakterasse: false,
      hage: false,
      ingen: false,
    },
    bathCondition: "",
    parking: "",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + this.props.accessToken,
    },
  };

  constructor(props) {
    super(props);
    //console.log(this.props);
    this.setState({ offer: localStorage.getItem("offer") });
    this.sendEmail = this.sendEmail.bind(this);
  }

  fetchBuildingCadastre = async (selectedUnit) => {
    /*     let cadastresLink
    if (this.state.selectedFloor !== null && this.state.addressBuildings.length >0) {
      cadastresLink = this.state.addressBuildings.find(building=>building.key = this.state.selectedFloor.ident.buildingNumber).links.cadastres.href
    }
    if (!cadastresLink) return
    const res = await axios.get(cadastresLink,{headers:this.state.headers})
    const json = await res.json()
    console.log(json) */
    this.setState({ ownershipIsLoaded: false });
    /* let occupiedUnit
if (selectedFloor !== null && this.state.occupiedUnits.length > 0) {
  occupiedUnit = this.state.occupiedUnits.find(unit=>{
    //console.log(unit)
    //console.log(this.state.selectedFloor)
    return unit.floorNumber === selectedFloor.ident.floorNumber && unit.floorLevelCode.code === selectedFloor.ident.floorLevelCode.code})
    //console.log(occupiedUnit)
  }
  console.log('occupiedUnit',occupiedUnit)
  if (!occupiedUnit) { */
    if (!selectedUnit) {
      this.setState({ ownershipIsLoaded: true });
      return;
    }
    const res = await axios.get(
      `${selectedUnit.links.cadastre.href}?includeExtraFields=true`,
      { headers: this.state.headers }
    );
    //const json = await res.json()
    console.log(res.data.item);
    const {
      ownedByCooperative,
      ownedByCorporation,
      ownedByHousingCorporation,
    } = res.data.item;
    console.log("ownedByCooperative", ownedByCooperative);
    this.setState({
      ownershipIsLoaded: true,
      ownedByCorporation,
      ownedByCooperative,
      ownedByHousingCorporation,
    });
    const buildingResponse = await axios.get(selectedUnit.links.building.href, {
      headers: this.state.headers,
    });
    console.log(buildingResponse);
    this.setState({
      constructionDate: buildingResponse.data.item.updatedDate.ddmmyyyy,
    });
  };

  componentDidMount() {
    axios
      .get(
        `https://beta-api.ambita.com/realty/v1/addresses/${this.props.addressId}/separatelyoccupiedunits/?includeExtraFields=true&pagesize=100&page=1`,
        { headers: this.state.headers }
      )
      .then(({ data }) => {
        console.log(data);
        this.setState({ separatelyOccupiedUnits: data.items });
      });
    axios
      .get(
        `https://beta-api.ambita.com/realty/v1/addresses/${this.props.addressId}/buildings/?includeExtraFields=true`,
        {
          headers: this.state.headers,
        }
      )
      .then((res) => {
        const { data } = res;
        //console.log('address\'s buildings',data)
        this.setState({
          addressBuildings: data.items,
        });
        return data;
      });

    axios
      .get(
        `https://beta-api.ambita.com/realty/v1/addresses/${this.props.addressId}/cadastres/?includeExtraFields=true`,

        {
          headers: this.state.headers,
        }
      )
      .then((res) => {
        const { data } = res;
        return data;
      });

    axios
      .get(
        `https://beta-api.ambita.com/realty/v1/addresses/${this.props.addressId}/separatelyoccupiedunits/?includeExtraFields=true`,

        {
          headers: this.state.headers,
        }
      )
      .then((res) => {
        const { data } = res;
        console.log("Occupied Units", data);
        this.setState({
          occupiedUnits: data.items,
        });
      });

    axios
      .get(
        `https://beta-api.ambita.com/realty/v1/addresses/${this.props.addressId}/cadastres/?includeExtraFields=true`,
        {
          headers: this.state.headers,
        }
      )
      .then((res) => {
        const { data } = res;
        //console.log("Cadatstres", data.items[0]);
        const cadastreId = data.items[0].key;
        this.setState({ ownership: data.items });

        axios
          .get(
            `https://beta-api.ambita.com/realty/v1/cadastres/${cadastreId}/buildings/?includeExtraFields=true`,
            {
              headers: this.state.headers,
            }
          )
          .then((res) => {
            const { data } = res;
            //console.log(data);
            return data;
          });

        axios
          .get(
            `https://beta-api.ambita.com/realty/v1/cadastres/${cadastreId}/separatelyoccupiedunits/?includeExtraFields=true`,
            {
              headers: this.state.headers,
            }
          )
          .then((res) => {
            const { data } = res;
            return data;
          });

        //console.log("building -----------------------");
        axios
          .get(
            `https://beta-api.ambita.com/realty/v1/cadastres/${cadastreId}/buildings/?includeExtraFields=true`,
            {
              headers: this.state.headers,
            }
          )
          .then((res) => {
            const { data } = res;
            this.setState({ buildings: data.items });
          });

        axios
          .get(
            `https://beta-api.ambita.com/realty/v1/cadastres/${cadastreId}/separatelyoccupiedunits/?includeExtraFields=true`,
            {
              headers: this.state.headers,
            }
          )
          .then((res) => {
            const { data } = res;

            this.setState({ Rooms: data.items });
            this.setState({ numberOfBathRooms: data.items });
            this.setState({ numberOfRooms: data.items });
            this.setState({ usableArea: data.items });
          });
        /*             if(this.state.separatelyOccupiedUnits.unit.unitNumber !== true) {
              return this.state.separatelyOccupiedUnits.unit.unitNumber === "Annet" 
            } */
      });
  }

  sendEmail(e) {
    e.preventDefault();

    let ownership = "";
    if (this.state.ownershipIsLoaded) {
      ownership = "Ingen data tilgjengelig";
      if (this.state.ownedByCooperative) {
        ownership =
          "Eiendommen er eid av aksjeselskap (eierandel eller hele eiendommen)";
      } else if (this.state.ownedByCorporation) {
        ownership =
          "Borettslag - Eiendommen er eid av borettslag (eierandel eller hele eiendommen)";
      } else if (this.state.ownedByHousingCorporation) {
        ownership =
          "Aksjebolig - Eiendommen er eid av boligaksjeselskap (aksjebolig) (eierandel eller hele eiendommen)";
      }
    }

    const params = {
      from_name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      addressBuildings: this.state.userInputBuildings
        ? `user input - ${this.state.userInputBuildings}`
        : this.props.addressName,
      numberOfBathRooms: this.state.userInputBathRooms
        ? `user input - ${this.state.userInputBathRooms}`
        : this.state.selectedUnit.numberOfBathRooms,
      usableArea: this.state.userInputUsableArea
        ? `user input - ${this.state.userInputUsableArea}`
        : this.state.selectedUnit?.usableArea,
      constructionDate: this.state.userInputConstructionDate
        ? `user input - ${this.state.userInputConstructionDate}`
        : this.state.constructionDate,
      ownedByCorporation: this.state.userInputOwnership
        ? `user input - ${this.state.userInputOwnership}`
        : ownership,

      // buildingRelationship:this.state.buildingRelationship,
      kitchenCondition: this.state.kitchenCondition,
      buildingCondition: JSON.stringify(this.state.buildingCondition),
      bathCondition: this.state.bathCondition,
      parking: this.state.parking,
      facilities: JSON.stringify(this.state.facilities),
    };
    emailjs
      .send(
        "service_37vmk6m",
        "template_oeu49xc",
        params,
        "user_XetiimH8A6crIVOB4iAuQ"
      )
      .then(
        (result) => {
          console.log(result.text);
          this.setState({
            name: "",
            phone: "",
            email: "",
            emailSentMessage: "Din forespørsel er sendt",
          });
        },

        (error) => {
          console.log(error.text);
          this.setState({
            emailSentMessage:
              "Emailen din ble ikke sendt, vær så snill å prøv igjen",
          });
        }
      );
  }

  render() {
    const data = JSON.parse(localStorage.getItem("offer"));

    const importAll = (r) => {
      let images = {};
      r.keys().map((item) => {
        images[item.replace("./", "")] = r(item);
      });
      return images;
    };
    const images = importAll(
      require.context("./assets/images", false, /\.(png|jpe?g|svg|JPE?G)$/)
    );

    let floors = {};
    if (this.state.separatelyOccupiedUnits?.length > 0) {
      console.log("here");
      floors = this.state.separatelyOccupiedUnits.reduce((floors, unit) => {
        return {
          ...floors,
          [unit.floorNumber]: [
            ...(floors[unit.floorNumber] ? floors[unit.floorNumber] : []),
            unit,
          ],
        };
      }, {});
    }
    console.log("floors", floors);

    return (
      <React.Fragment>
        <div className="offerHeader">
          <Link to="/">
          <img
            className="logoAsk"
            src={images["logoAsk.png"].default}
            alt="SelvSolgt Hjemmeside bilde"
          />
          </Link>
          <div id="offerSite">
            <div id="addressInfo">
              Adresse: {data.street.streetName}
              {"\n"}{" "}
              {data.ident.addressNumber +
                String(data.ident.letter ? data.ident.letter : "")}
              , {String(data.street.postalCode).padStart(4, "0")}{" "}
              {data.street.postalPlace}
            </div>
            <h2 className="headersOffer">Velg etasje</h2>

            {Object.keys(floors).length > 0 && (
              <div id="theFloors">
                {Object.keys(floors).map((floorNumber) => {
                  return (
                    <div
                      className="placeOfLiving"
                      key={floorNumber}
                      onClick={(e) =>
                        this.setState({ selectedFloor: floorNumber })
                      }
                    >
                      {floorNumber !== "0"
                        ? `${floorNumber}. Etasje`
                        : `Ingen etasje (Annet)`}
                    </div>
                  );
                })}
              </div>
            )}

            {this.state.selectedFloor &&
              this.state.separatelyOccupiedUnits.length > 0 && (
                <div className="makeColumn">
                  <h2 className="headersOffer">Velg bolig</h2>
                  <div className="boliger">
                    {floors[this.state.selectedFloor].map((unit) => {
                      return (
                        <div
                          className="placeOfLiving"
                          key={unit.key}
                          onClick={() => {
                            this.setState({ selectedUnit: unit });
                            this.fetchBuildingCadastre(unit);
                          }}
                        >
                          {unit.unitNumber ? unit.unitNumber : "Annet"}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

            {this.state.selectedUnit !== null && (
              <>
                <div className="offerInfo" id="numberOfBathRooms">
                  <h2 className="headersOffer">Antall bad</h2>
                  {`${
                    this.state.selectedUnit?.numberOfBathRooms
                      ? this.state.selectedUnit.numberOfBathRooms
                      : "Ingen data tilgjengelig"
                  }`}
                  <input
                    className="inputUserInfo"
                    type="text"
                    id="userInputBathRooms"
                    onChange={(event) => {
                      this.setState({ userInputBathRooms: event.target.value });
                    }}
                  ></input>
                  <label for="userInputBathRooms">
                    Skriv inn riktig info om dette ikke stemmer eller data
                    mangler
                  </label>
                </div>

                <div className="offerInfo" id="numberOfRooms">
                  <h2 className="headersOffer">Antall oppholdsrom</h2>
                  {`${
                    this.state.selectedUnit?.numberOfRooms
                      ? this.state.selectedUnit.numberOfRooms
                      : "Ingen data tilgjengelig"
                  }`}
                  <input
                    className="inputUserInfo"
                    type="text"
                    id="userInputConstructionDate"
                    onChange={(event) => {
                      this.setState({ userInpu: event.target.value });
                    }}
                  ></input>
                  <label for="userInputConstructionDate">
                    Skriv inn riktig info om dette ikke stemmer eller data
                    mangler
                  </label>
                </div>

                <div className="offerInfo" id="usableArea">
                  <h2 className="headersOffer">Bruksareal for boligen</h2>
                  {`${
                    this.state.selectedUnit?.usableArea
                      ? this.state.selectedUnit.usableArea + " kvadratmeter"
                      : "Ingen data tilgjengelig"
                  }`}
                  <input
                    className="inputUserInfo"
                    type="text"
                    id="userInputUsableArea"
                    onChange={(event) => {
                      this.setState({
                        userInputUsableArea: event.target.value,
                      });
                    }}
                  ></input>
                  <label for="userInputUsableArea">
                    Skriv inn riktig info om dette ikke stemmer eller data
                    mangler
                  </label>
                </div>

                <div className="offerInfo" id="usableArea">
                  <h2 className="headersOffer">Når boligen ble bygd</h2>
                  {`${
                    this.state.constructionDate
                      ? this.state.constructionDate
                      : "Ingen data tilgjengelig"
                  }`}
                  <input
                    className="inputUserInfo"
                    type="text"
                    id="userInputConstructionDate"
                    onChange={(event) => {
                      this.setState({
                        userInputConstructionDate: event.target.value,
                      });
                    }}
                  ></input>
                  <label for="userInputConstructionDate">
                    Skriv inn riktig info om dette ikke stemmer eller data
                    mangler
                  </label>
                </div>

                <div className="offerInfo" id="owner">
                  <h2 className="headersOffer">
                    Hva slags type eierskap boligen har
                  </h2>
                  {this.state.ownedByCorporation &&
                    "Borettslag - Eiendommen er eid av borettslag (eierandel eller hele eiendommen)"}
                  {this.state.ownedByCooperative &&
                    "Eiendommen er eid av aksjeselskap (eierandel eller hele eiendommen)"}
                  {this.state.ownedByHousingCorporation &&
                    "Aksjebolig - Eiendommen er eid av boligaksjeselskap (aksjebolig) (eierandel eller hele eiendommen)"}
                  {this.state.ownershipIsLoaded &&
                    !this.state.ownedByCooperative &&
                    !this.state.ownedByCorporation &&
                    !this.state.ownedByHousingCorporation && (
                      <p>Ingen data tilgjengelig</p>
                    )}

                  <input
                    className="inputUserInfo"
                    type="text"
                    id="userInputOwnership"
                    onChange={(event) => {
                      this.setState({ userInputOwnership: event.target.value });
                    }}
                  ></input>
                  <label for="userInputOwnership">
                    Skriv inn riktig info om dette ikke stemmer eller data
                    mangler
                  </label>
                </div>
              </>
            )}
          </div>

          <div className="container">
            <h2 className="containerHeader">
              Hvilken tilstand er kjøkkenet i?
            </h2>
            <div
              className="radio"
              aria-label="gender"
              name="gender1"
              value={"" /*inputValue*/}
              onChange={() => {} /*handleChange*/}
            >
              <ul>
                <li>
                  <input
                    type="radio"
                    id="f-option2"
                    name="selector2"
                    onChange={(e) =>
                      this.setState({ kitchenCondition: e.currentTarget.value })
                    }
                    value="Ser nytt ut"
                    checked={this.state.kitchenCondition === "Ser nytt ut"}
                  />
                  <label for="f-option2">Ser nytt ut</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="radio"
                    id="s-option2"
                    name="selector2"
                    onChange={(e) =>
                      this.setState({ kitchenCondition: e.currentTarget.value })
                    }
                    value="Litt slitasje, et typisk bad"
                    checked={
                      this.state.kitchenCondition ===
                      "Litt slitasje, et typisk bad"
                    }
                  />
                  <label for="s-option2">
                    Litt slitasje, et typisk kjøkken
                  </label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="radio"
                    id="t-option2"
                    name="selector2"
                    onChange={(e) =>
                      this.setState({ kitchenCondition: e.currentTarget.value })
                    }
                    value="Trenger oppussing, har større skader"
                    checked={
                      this.state.kitchenCondition ===
                      "Trenger oppussing, har større skader"
                    }
                  />
                  <label for="t-option2">
                    Trenger oppussing, har større skader
                  </label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="container">
            <h2 className="containerHeader">Er det noen skader på boligen?</h2>
            <div
              className="radio"
              aria-label="gender"
              name="gender1"
              value={"" /*inputValue*/}
              onChange={() => {} /*handleChange*/}
            >
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="f-option3"
                    name="selector3"
                    onChange={(e) =>
                      this.setState({
                        buildingCondition: {
                          ...this.state.buildingCondition,
                          fuktskader: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Fuktskader"
                    checked={this.state.buildingCondition.fuktskader === true}
                  />
                  <label for="f-option3">Fuktskader</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="s-option3"
                    name="selector3"
                    onChange={(e) =>
                      this.setState({
                        buildingCondition: {
                          ...this.state.buildingCondition,
                          elektrisitetfeil: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Feil på elektrisitet"
                    checked={
                      this.state.buildingCondition.elektrisitetfeil === true
                    }
                  />
                  <label for="s-option3">Feil på elektrisitet</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="a-option3"
                    name="selector3"
                    onChange={(e) =>
                      this.setState({
                        buildingCondition: {
                          ...this.state.buildingCondition,
                          fundamentskader: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Fundamentskader"
                    checked={
                      this.state.buildingCondition.fundamentskader === true
                    }
                  />
                  <label for="a-option3">Fundamentskader</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="b-option3"
                    name="selector3"
                    onChange={(e) =>
                      this.setState({
                        buildingCondition: {
                          ...this.state.buildingCondition,
                          sopp: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Sopp"
                    checked={this.state.buildingCondition.sopp === true}
                  />
                  <label for="b-option3">Sopp</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="c-option3"
                    name="selector3"
                    onChange={(e) =>
                      this.setState({
                        buildingCondition: {
                          ...this.state.buildingCondition,
                          lekasje: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Lekasje"
                    checked={this.state.buildingCondition.lekasje === true}
                  />
                  <label for="c-option3">Lekasje</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="d-option3"
                    name="selector3"
                    onChange={(e) =>
                      this.setState({
                        buildingCondition: {
                          ...this.state.buildingCondition,
                          skadedyr: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Skadedyr"
                    checked={this.state.buildingCondition.skadedyr === true}
                  />
                  <label for="d-option3">Skadedyr</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="t-option3"
                    name="selector3"
                    onChange={(e) =>
                      this.setState({
                        buildingCondition: {
                          ...this.state.buildingCondition,
                          annet: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Annet"
                    checked={this.state.buildingCondition.annet === true}
                  />
                  <label for="t-option3">Annet</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="container">
            <h2 className="containerHeader">Hvilken tilstand er badet i?</h2>
            <div
              className="radio"
              aria-label="gender"
              name="gender"
              value={"" /*inputValue*/}
              onChange={() => {} /*handleChange*/}
            >
              <ul>
                <li>
                  <input
                    type="radio"
                    id="f-option4"
                    name="selector4"
                    onChange={(e) =>
                      this.setState({ bathCondition: e.currentTarget.value })
                    }
                    value="Ser nytt ut"
                    checked={this.state.bathCondition === "Ser nytt ut"}
                  />
                  <label for="f-option4">Ser nytt ut</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="radio"
                    id="s-option4"
                    name="selector4"
                    onChange={(e) =>
                      this.setState({ bathCondition: e.currentTarget.value })
                    }
                    value="Litt slitasje, et typisk bad"
                    checked={
                      this.state.bathCondition ===
                      "Litt slitasje, et typisk bad"
                    }
                  />
                  <label for="s-option4">Litt slitasje, et typisk bad</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="radio"
                    id="t-option4"
                    name="selector4"
                    onChange={(e) =>
                      this.setState({ bathCondition: e.currentTarget.value })
                    }
                    value="Trenger oppussing, har større skader"
                    checked={
                      this.state.bathCondition ===
                      "Trenger oppussing, har større skader"
                    }
                  />
                  <label for="t-option4">
                    Trenger oppussing, har større skader
                  </label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="container">
            <h2 className="containerHeader">
              Er garasje eller parkering inkludert med boligen?
            </h2>
            <div
              className="radio"
              aria-label="gender"
              name="gender1"
              value={"" /*inputValue*/}
              onChange={() => {} /*handleChange*/}
            >
              <ul>
                <li>
                  <input
                    type="radio"
                    id="f-option6"
                    name="selector6"
                    onChange={(e) =>
                      this.setState({ parking: e.currentTarget.value })
                    }
                    value="Garasjeplass"
                    checked={this.state.parking === "Garasjeplass"}
                  />
                  <label for="f-option6">Garasjeplass</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="radio"
                    id="s-option6"
                    name="selector6"
                    onChange={(e) =>
                      this.setState({ parking: e.currentTarget.value })
                    }
                    value="Parkeringsplass utendørs"
                    checked={this.state.parking === "Parkeringsplass utendørs"}
                  />
                  <label for="s-option6">Parkeringsplass utendørs</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="radio"
                    id="t-option6"
                    name="selector6"
                    onChange={(e) =>
                      this.setState({ parking: e.currentTarget.value })
                    }
                    value="Leie av garasje/parkering"
                    checked={this.state.parking === "Leie av garasje/parkering"}
                  />
                  <label for="t-option6">Leie av garasje/parkering</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="radio"
                    id="a-option6"
                    name="selector6"
                    onChange={(e) =>
                      this.setState({ parking: e.currentTarget.value })
                    }
                    value="Ingen av delene"
                    checked={this.state.parking === "Ingen av delene"}
                  />
                  <label for="a-option6">Ingen av delene</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="container">
            <h2 className="containerHeader">
              Har boligen noen av følgende fasiliteter?
            </h2>
            <div
              className="checkbox"
              aria-label="gender"
              name="gender1"
              value={"" /*inputValue*/}
              onChange={() => {} /*handleChange*/}
            >
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="f-option7"
                    name="selector7"
                    onChange={(e) =>
                      this.setState({
                        facilities: {
                          ...this.state.facilities,
                          heis: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Heis"
                    checked={this.state.facilities.heis === true}
                  />
                  <label for="f-option7">Heis</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="s-option7"
                    name="selector7"
                    onChange={(e) =>
                      this.setState({
                        facilities: {
                          ...this.state.facilities,
                          balkong: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Balkong/Vest-Sør"
                    checked={this.state.facilities.balkong === true}
                  />
                  <label for="s-option7">Balkong/Vest-Sør</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="a-option7"
                    name="selector7"
                    onChange={(e) =>
                      this.setState({
                        facilities: {
                          ...this.state.facilities,
                          balkongAnnenRetning: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Balkong annen retning"
                    checked={this.state.facilities.balkongAnnenRetning === true}
                  />
                  <label for="a-option7">Balkong annen retning</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="b-option7"
                    name="selector7"
                    onChange={(e) =>
                      this.setState({
                        facilities: {
                          ...this.state.facilities,
                          takterasse: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Privat takterasse"
                    checked={this.state.facilities.takterasse === true}
                  />
                  <label for="b-option7">Privat takterasse</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="c-option7"
                    name="selector7"
                    onChange={(e) =>
                      this.setState({
                        facilities: {
                          ...this.state.facilities,
                          fellesTakterasse: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Felles takterasse"
                    checked={this.state.facilities.fellesTakterasse === true}
                  />
                  <label for="c-option7">Felles takterasse</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="d-option7"
                    name="selector7"
                    onChange={(e) =>
                      this.setState({
                        facilities: {
                          ...this.state.facilities,
                          hage: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Hage"
                    checked={this.state.facilities.hage === true}
                  />
                  <label for="d-option7">Hage</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="e-option7"
                    name="selector7"
                    onChange={(e) =>
                      this.setState({
                        facilities: {
                          ...this.state.facilities,
                          ingen: e.currentTarget.checked,
                        },
                      })
                    }
                    value="Ingen av delene"
                    checked={this.state.facilities.ingen === true}
                  />
                  <label for="e-option7">Ingen av delene</label>

                  <div className="check">
                    <div className="inside"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <h2 id="justCenter">Send din forespørsel</h2>

          {this.state.emailSentMessage && <p>{this.state.emailSentMessage}</p>}
          <input
            className="contactInput"
            type="text"
            name="user_name"
            placeholder="Navn"
            pattern="[æøåÆØÅA-Za-z0-9._%+-]{2,}$"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
            required
          />
          <input
            className="contactInput"
            type="text"
            id="phone"
            name="phone"
            placeholder="Nummer"
            pattern="[0-9]"
            value={this.state.phone}
            onChange={(e) => this.setState({ phone: e.target.value })}
            required
          />
          <input
            className="contactInput"
            type="email"
            id="email"
            name="reply_to"
            placeholder="Epost"
            pattern="[æøåÆØÅA-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            size="30"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            required
          />
          <button id="sendEmail" type="submit" onClick={this.sendEmail}>
            Send
          </button>

          <div id="div" component="fieldset"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default Offer;
