import "./CSS/Home.scss";
import "./CSS/HomeResponsive.scss";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState } from "react";
import { validInput } from "./regex.js";
import { getAPIData } from "./services/HouseHoldApi";

function Home({ setAddressId, setAddressName }) {
  const importAll = (r) => {
    let images = {};
    r.keys().map((item) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  };
  const images = importAll(
    require.context("./assets/images", false, /\.(png|jpe?g|svg|JPE?G)$/)
  );

  // const [validInput, setValidError] = useState();
  const [validationError, setValidationError] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [suggestionsData, setSuggestionsData] = useState(null);

  const validate = () => {
    if (!inputValue || inputValue.length < 3 || !validInput.test(inputValue)) {
      setValidationError(true);
      setSuggestionsData(null);
      return false;
    } else {
      setValidationError(false);
      return true;
    }
  };

  const handleChange = async ({ target: { value } }) => {
    setInputValue(value);
    if (value.length > 2) {
      const data = await getAPIData(value);
      setSuggestionsData(data);
      setIsOpen(true);
    } else {
      setSuggestionsData(null);
      setIsOpen(false);
    }
  };
  const handleChange2 = async ({ target: { value } }) => {
    setInputValue(value);
    if (value.length > 2) {
      const data = await getAPIData(value);
      setSuggestionsData(data);
      setIsOpen(true);
    } else {
      setSuggestionsData(null);
      setIsOpen(false);
    }
  };

  const handleItemSelect = (obj) => {
    let str =
      obj.street.streetName +
      String(obj.ident.letter ? true : "") +
      " " +
      obj.ident.addressNumber +
      " " +
      String(obj.street.postalCode).padStart(4, "0") +
      " " +
      obj.street.postalPlace;
    setInputValue(str);
    setAddressName(str);
    setSelectedOffer(obj);
    setAddressId(obj.key);
    console.log(obj);
    //setOwnershipId(obj.items[0].position.properties.links.cadastres.key);
    setIsOpen(false);
  };

  const getSaleOffer = () => {
    const isValid = validate();
    if (isValid) {
      //let url = 'localhost:3000/offer'
      localStorage.setItem("offer", JSON.stringify(selectedOffer));
      //window.open(url, "_blank");
    }
  };
 
  return (
    <React.Fragment>
      <header>
        <div id="checkEstate">
          <div className="headerLinks">
          <img
                className="iconImgs"
                src={images["logo.png"].default}
                alt="SelvSolgt Hjemmeside bilde"
              />
            
            <div className="headerRow">
              <Link to="/">
                <div className="linksHeader"></div>
              </Link>
              <Link to="/">
                <div className="linksHeader">Hvorfor oss</div>
              </Link>
              <Link to="/">
                <div className="linksHeader">FAQ</div>
              </Link>
              <Link to="/">
                <div className="linksHeader">Kontakt Oss</div>
              </Link>
            </div>
          </div>
          <h1 className="readyTitle">Klar, ferdig, solgt.</h1>
          {validationError && (
            <h2 className="errorMessage">
              Addressen du skrev er feil. Prøv en annen addresse.
            </h2>
          )}

          {!(
            suggestionsData &&
            suggestionsData.items &&
            suggestionsData.items.length > 0
          ) &&
            validationError && (
              <h2 className="errorMessage">
                Det er ingen resultater for dette nøkkelordet.
              </h2>
            )}

          <div id="rowSearch">
            <div id="checkPlace">
              <div id="inputInfo">
                <input
                  id="checkEstateInput"
                  type="input"
                  placeholder="Skriv inn adresse"
                  value={inputValue}
                  onChange={handleChange}
                  style={{ color: "black" }}
                ></input>
              </div>

              <Link to="/Offer">
                <button id="checkEstateButton" onClick={getSaleOffer}>
                  FÅ SALG TILBUD
                </button>
              </Link>
              {suggestionsData &&
              suggestionsData.items &&
              suggestionsData.items.length > 0 &&
              isOpen ? (
                <div id="addressDisplay">
                  {suggestionsData.items.map((data) => (
                    <div
                      id="searchContainer"
                      onClick={() => handleItemSelect(data)}
                      key={data.key}
                    >
                      <div id="addressInfo">
                        <span id="hoverInfo">
                          {data.street.streetName + " "}
                          {" " +
                            data.ident.addressNumber +
                            String(data.ident.letter ? data.ident.letter : "") +
                            ","}
                          {" " +
                            String(data.street.postalCode).padStart(
                              4,
                              "0"
                            )}{" "}
                          {" " + data.street.postalPlace}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>

          <h2 id="underHeader">
            Få et tilbud innen minutter, selg innen få dager.
          </h2>
        </div>

        <div className="divBackground">
          <h2 id="threeSteps">
            3 enkle trinn uten problemer med visjon eller andre problemer.
          </h2>
          <div className="row">
            <div className="rowSplit">
              <img
                className="iconImgs"
                src={images["stepsImg1.JPG"].default}
                alt="SelvSolgt Hjemmeside bilde"
              />
              <h4>STEG 1</h4>
              <h5>Få et tilbud innen minutter</h5>
              <p className="description">
                Se ditt foreløpige tilbud på bare noen få enkle trinn.
              </p>
            </div>

            <div className="rowSplit">
              <img
                className="iconImgs"
                src={images["stepsImg2.JPG"].default}
                alt="SelvSolgt Hjemmeside bilde"
              />
              <h4>STEG 2</h4>
              <h5>Gjør en video gjennomgang</h5>
              <p className="description">
               Vis oss ditt hjem slik at vi kan gi vårt best tilbud.
              </p>
            </div>

            <div className="rowSplit">
              <img
                className="iconImgs"
                src={images["stepsImg3.JPG"].default}
                alt="SelvSolgt Hjemmeside bilde"
              />
              <h4>STEG 3</h4>
              <h5>Fullfør avtale og flytt inn uten stress</h5>
              <p className="description">
                Velg når du vil fullføre, betal innen dager.
              </p>
            </div>
          </div>

          <button id="sellOffer" type="button">
            Ja, jeg har lyst på en gratis og ikke-bindende salgs tilbud til mitt
            hus!
          </button>
        </div>
      </header>

      <div className="backgroundSell">
        <div id="sellCost">
          Hva koster et tradisjonell salg og{"\n"}
          hvor mye tid tar hele salgsprosessen?
        </div>

        <div className="brokerInfo">
          <div id="containTxt">
            <div className="flexMeglerTxt">
              <div className="moveRight">
                <span className="emphasizeTxt">2%</span>
                {"\n"}Megler Kostnad
              </div>
              <div className="moveLeft">
                <span className="emphasizeTxt">10000</span>
                {"\n"}Valgfri Renter
              </div>
            </div>

            <div className="flexMeglerTxt">
              <div className="moveRight">
                <span className="emphasizeTxt">2%</span>
                {"\n"}Megler Kostnad
              </div>
              <div className="moveLeft">
                <span className="emphasizeTxt">10000</span>
                {"\n"}Valgfri Renter
              </div>
            </div>
          </div>

          <div id="equalSign" className="largeFont">
            =
          </div>
          <div className="largestFont">
            <span className="emphasizeTxt">NOK 5 Million,-</span>
            {"\n"} Kostnad estimert på huset
          </div>
        </div>
      </div>

      <div id="selectSelvsolgt">
        <div id="selectSelvsolgtColumn">
          <h2 id="whySelect">Hvorfor velge           <img
                className="logoAsk"
                src={images["logoAsk.png"].default}
                alt="SelvSolgt Hjemmeside bilde"
              /></h2>
          <div id="whySelectTxt">
            Vi tilbyr deg å spare tid og stress med prisen i forhold{"\n"} til
            den tradisjonelle metoden. Du går videre uten å kaste bort{"\n"} mer
            tid og bekymring.
          </div>
          <button id="readMore" type="button">
            Les mer
          </button>
        </div>
        <img
          className="sSImg"
          src={images["whySelectImg.JPG"].default}
          alt="SelvSolgt Hjemmeside bilde"
        />
      </div>

      <div id="decideHouseTitle">
        <h4 id="dHTh4">
          <span id="yourHouse">Ditt hus</span> <span id="mouth">{">"}</span>
          <span id="decide">Du bestemmer</span>
        </h4>
        <h6 id="variousOption">
          Selvsolgt gir deg varierte
          <span id="tilbud"> tilbud å velge ifra</span> som passer deg best
        </h6>
      </div>

      <div className="tableInfo">
        <div className="columnInfo">
          <div className="tableInfoHeaders"></div>
          <div className="columnContent changeFont">Tid det tar</div>
          <div className="columnContent changeFont">Markedspris</div>
          <div className="columnContent changeFont">Andre ting</div>
        </div>

        <div className="columnInfo">
          <div className="tableInfoHeaders">Selg til oss</div>
          <div className="columnContent">45 Dager</div>
          <div className="columnContent">4.75 - 5.25 Millioner</div>
          <div className="columnContent">Andre ting</div>
        </div>

        <div className="columnInfo">
          <div className="tableInfoHeaders">
            Vi administrerer salgsprosessen
          </div>
          <div className="columnContent">45 Dager</div>
          <div className="columnContent">4.75 - 5.25 Millioner</div>
          <div className="columnContent">Andre ting</div>
        </div>

        <div className="columnInfo">
          <div className="tableInfoHeaders">
            Vil fortsatt ha Tradisjonell måte?
          </div>
          <div className="columnContent">Usikker</div>
          <div className="columnContent">Usikker</div>
          <div className="columnContent">Usikker</div>
        </div>
      </div>

      <div className="tableInfoResponsive">
        <div className="tableInfoHeadingResponsive">
          <div className="tableInfoHeadersResponsive">Selg til oss</div>
          <div className="tableInfoHeadersResponsive">
            Vi administrerer salgsprosessen
          </div>
          <div className="tableInfoHeadersResponsive">
            Vil fortsatt ha Tradisjonell måte?
          </div>
        </div>
        <div className="columnInfoResponsive">
          <div className="columnHeaderResponsive">Tid det tar</div>
          <div className="columnContentResponsive">1 uke</div>
          <div className="columnContentResponsive">4.75 - 5.25 Millioner</div>
          <div className="columnContentResponsive">Usikker</div>
        </div>

        <div className="columnInfoResponsive">
          <div className="columnHeaderResponsive">Markedspris</div>
          <div className="columnContentResponsive">45 Dager</div>
          <div className="columnContentResponsive">4.75 - 5.25 Millioner</div>
          <div className="columnContentResponsive">Usikker</div>
        </div>

        <div className="columnInfoResponsive">
          <div className="columnHeaderResponsive">Andre ting</div>
          <div className="columnContentResponsive">Andre ting</div>
          <div className="columnContentResponsive">Andre ting</div>
          <div className="columnContentResponsive">Usikker</div>
        </div>
      </div>

      <div id="frequentlyAsked">
        <h2 className="faTitle">Gjentatte Spørsmål</h2>
        <div className="faBar">
          <img
            className="sSImg"
            src={images["questionIcon.JPG"].default}
            alt="SelvSolgt Hjemmeside bilde"
          />
          <h4 /*onclick={displayQuestion}*/ className="faQuestion">
            Er det noen kostnader eller obligasjoner ved å be om pris?
          </h4>
        </div>
        <div className="faBar">
          <img
            className="sSImg"
            src={images["questionIcon.JPG"].default}
            alt="SelvSolgt Hjemmeside bilde"
          />
          <h4 className="faQuestion">
            Er det noen kostnader eller obligasjoner ved å be om pris?
          </h4>
        </div>
        <div className="faBar">
          <img
            className="sSImg"
            src={images["questionIcon.JPG"].default}
            alt="SelvSolgt Hjemmeside bilde"
          />
          <h4 className="faQuestion">
            Er det noen kostnader eller obligasjoner ved å be om pris?
          </h4>
        </div>
      </div>

      <div id="contactDiv">
        <div id="contact">
          <h2 id="contactHeader">Kontakt Oss</h2>
          <div>support@selvsolgt.com</div>
          <div>92892877</div>
          <h1 id="steder">Steder</h1>
          <div id="countyDiv">
            <div className="county"> Troms og Finnmark</div>
            <div className="county">Nordland</div>
            <div className="county">Trøndelag</div>
            <div className="county"> Møre og Romsdal</div>
            <div className="county"> Vestland</div>
            <div className="county"> Rogaland</div>
            <div className="county"> Agder</div>
            <div className="county"> Vestfold og Telemark</div>
            <div className="county">Viken</div>
            <div className="county">Oslo og Innlandet</div>
          </div>
        </div>
        <div id="mail">
          <img
            className="sSImg"
            src={images["mail.JPG"].default}
            alt="SelvSolgt Hjemmeside bilde"
          />
          <h4 id="expertHelp">Få ekspert hjelp til din innboks</h4>
          <div id="discorver">
            Utforsk metoder for å stige verdien til huset ditt og sørg for å
            være oppdatert med lokale trender.
          </div>
          <input
            id="emailAdress"
            placeholder="Skriv inn din epost adresse"
          ></input>
          <button id="readMore" type="button">
            Send tips
          </button>
        </div>
      </div>

      <footer>
        <div id="footerContent">
          <div id="footerFlex">
            <h1 className="selvsolgtFooter">SelvSolgt</h1>
            <div id="infoSelvsolgt">
              Ønsker du raskt salg til markedspris? Rask oppgjør og overtagelse
              når det passer deg? Vi kjøper din bolig ved at du sender oss litt
              info om din bolig. Vi kommer på en befaring og du vil da motta et
              bud innen 24 timer. Hvis du aksepterer signerer vi en kontrakt med
              overtagelses dato. Oppgjør av salget skjer gjennom megler eller
              advokat uten noe kostnader for deg. Vi tar over din bolig i den
              stand den er ved befaring uten noe risiko for at du vil få noe
              krav rettet mot deg for feil og mangler i fremtiden. Dermed
              slipper du arbeidet med reparasjoner og oppgradering. Du betaler
              et tjenestegebyr som blir trukket etter avtale fra salgssummen
              gjennom megler. Tradisjonell boligsalg kan ta opptil 3 - 4 måneder
              fra du bestemmer deg for salg til du får oppgjør. Vi forenkler
              alle stegene i boligsalgsprosessen slik at du skal få en mest
              mulig smidig og enkel salgsopplevelse.
            </div>
          </div>

          <div className="footerGrid">
            <h5>Selg ditt hjem</h5>

            <Link to="/">
              <h4>Be om tilbud</h4>
            </Link>
            <Link to="/">
              <h4>Priser</h4>
            </Link>
            <Link to="/">
              <h4>Anmeldelser</h4>
            </Link>
            <Link to="/">
              <h4>Fortellinger</h4>
            </Link>
          </div>

          <div className="footerGrid">
            <h5>Om oss</h5>

            <Link to="/">
              <h4>Selskapet</h4>
            </Link>
            <Link to="/">
              <h4>Karriere</h4>
            </Link>
            <Link to="/">
              <h4>Kontakt</h4>
            </Link>
            <Link to="/">
              <h4>Investorer</h4>
            </Link>
          </div>

          <div className="footerGrid">
            <h5>Ressurser</h5>

            <Link to="/">
              <h4>Blog</h4>
            </Link>
            <Link to="/">
              <h4>Veiledning</h4>
            </Link>
            <Link to="/">
              <h4>Anmeldelser</h4>
            </Link>
            <Link to="/">
              <h4>Fortellinger</h4>
            </Link>
          </div>
        </div>
        <h4 id="copyright">© Selvsolgt.no 2021</h4>
      </footer>
    </React.Fragment>
  );
}

export default Home;
