import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CSS/HouseHoldApi.scss";

function FetchApi({accessToken}) {
  const [error] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);


  const getAPIData = async () => {

    if (accessToken !== '') {
      const { data } = await axios.get(
        `https://beta-api.ambita.com/realty/v1/search/addresses/?query=Oslo&page=1&pagesize=10&positions=false&datum=UTM`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + accessToken,
  
          },
        }
      );
      console.log("returned data", data);
      setItems(data.items);
      setIsLoaded(true);
    }
  };
  useEffect(() => {
    document.cookie =
      "PLAY_SESSION = eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7IkF1dGhvcml6YXRpb24iOiJCZWFyZXIgNjEyZDA5YjItYTY3MC00ODg4LThmMjgtNzdjMmMyM2ExMmRjIn0sIm5iZiI6MTYyNzM5NDczOCwiaWF0IjoxNjI3Mzk0NzM4fQ.Pt4kQ_AsVqQZi47_pCrFYrR0XPq5y8eIgeRlu4xTe7k;domain = .ambita.com;";
    getAPIData();
  }, [accessToken]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div id="houseHoldsDisplayed">
        {items.map((data) => (
          <div key={data.key}>
            <div>
              <p>{data.street.postalPlace}+{data.street.postalCode}+{data.street.streetName}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default FetchApi;
