import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Home from "./Components/Home.js";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import FetchApi from "./Components/FetchApi.js";
import Offer from './Components/Offer.js';
import Dropdown from './Components/services/Dropdown.js';
function App() {
  //const [ownershipId, setOwnershipId] = useState('')
  const [addressId, setAddressId] = useState('')
  const [addressName, setAddressName] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [expiryTimestamp, setExpiryTimestamp] = useState(0)
  const shouldRenewToken = (expiryTimestamp > Date.now()/1000 - 30) || expiryTimestamp === 0 ? true : false
  useEffect(()=>{
    if (shouldRenewToken) {
      const getAccessToken = async () => {
        const { data: token_data } = await axios.post(
          "https://beta-api.ambita.com/authentication/v2/token",
          {
            grant_type: "password",
            client_id: "3SEV_SWG_y81f",
            client_secret: "143b138c310fc08fa",
            username: "50290RESTAPI",
            password: "pAdCdF:C6:",
          },
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );
        const { access_token, expires_in } = token_data
        setAccessToken(access_token)
        const expiresAt = Date.now()/1000 + expires_in*1
        setExpiryTimestamp(expiresAt)
      }
      getAccessToken()
    }
  },[shouldRenewToken])
  return (
    <React.Fragment>
    <Router>
      {/* <Routes> */}
      <Route path="/" exact>
        <Home setAddressId={setAddressId} setAddressName={setAddressName}/>
      </Route>

      <Route path="/Offer" exact>
        <Offer accessToken={accessToken} addressId={addressId} addressName={addressName}/>
      </Route>
      {/* </Routes> */}
    </Router>
    </React.Fragment>
  );
}

export default App;
