import axios from "axios";

export const getAPIData = async (inputQuery="") => {
    const { data: token_data } = await axios.post(
      "https://beta-api.ambita.com/authentication/v2/token",
      {
        grant_type: "password",
        client_id: "3SEV_SWG_y81f",
        client_secret: "143b138c310fc08fa",
        username: "50290RESTAPI",
        password: "pAdCdF:C6:",
      },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    const { access_token } = token_data;
    localStorage.setItem("token", access_token);
    console.log(access_token);
    const { data } = await axios.get(
      `https://beta-api.ambita.com/realty/v1/search/addresses/?query=${inputQuery}&page=1&pagesize=10&positions=false&datum=UTM`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      }
    );
    console.log("returned data", data);
    return data;
  };